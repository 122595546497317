<template>
  <div class="header__lang">
    <a
      :href="pathForUK"
      @click="switchLocaleToUk"
      class="header__lang--link"
      :class="lang === 'uk' ? 'active' : ''"
    >Укр</a>
    <a
      :href="'/ru' + pathForRU"
      @click="switchLocaleToRu"
      class="header__lang--link"
      :class="lang === 'ru' ? 'active' : ''"
    >Ру</a>
  </div>
</template>

<script>
export default {
  name: "lang-switcher",
  computed: {
    path: {
      get(){
          return this.$router.currentRoute.value.fullPath
      }
    },
    lang() {
      return this.$i18n.locale
    },
    pathForUK() {
        let url = this.path
        url = url.replace('/ru', '/uk');
        return url
    },
    pathForRU() {
        let url = this.path
        url = url.replace('/uk', '');
        return url
    },
  },
  methods: {
    switchLocaleToUk(){
        this.$i18n.locale = 'uk'
        this.$store.commit('lang/changeLangToUk')
    },
    switchLocaleToRu(){
        this.$i18n.locale = 'ru'
        this.$store.commit('lang/changeLangToRu')
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";
.header {
  &__lang {
    display: flex;
    align-items: center;
    border: 1px solid #707070;
    padding: 5px 15px;

    &--link {
      text-decoration: underline;
      color: #f9b80e;

      &:nth-of-type(n + 2) {
        margin-left: 15px;
      }

      &.active {
        color: #929292;
        text-decoration: none !important;
        pointer-events: none;
        cursor: none;
      }

      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
